<template>
  <div>
    <!----- 搜索框 start------->
    <el-form :inline="true" class="search">
      <el-form-item label="名称">
        <el-input clearable v-model="searchData.name" placeholder="名称"/>
      </el-form-item>

      <el-form-item>
        <el-button @click="getProjects()">查询</el-button>
      </el-form-item>

        <el-form-item style="float: right">
          <el-button type="primary" @click.stop="clickCreate()">添加项目</el-button>
        </el-form-item>
    </el-form>
    <!----- 搜索框 end------->

    <!----- 表格 start------->
    <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="name" label="名称"/>
      <el-table-column prop="identity_name" label="公司名称"/>
      <el-table-column prop="total" label="累计付款"/>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="primary" size="small" @click="clickUpdate(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!----- 表格 end------->

    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          layout="prev, pager, next, total"
          :total="pageData.total"
          v-model:current-page="searchData.page"
          :page-size="searchData.per_page"
          @update:current-page="getProjects()"
          class="mt-4"
      />
    </div>
    <!----- 分页 end------->

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="formData.show" title="编辑项目" fullscreen append-to-body>
      <el-form
          ref="project"
          :rules="formData.rules"
          label-width="100px"
          :model="formData.data"
      >
        <el-form-item label="项目名称" prop="name">
          <el-input v-model="formData.data.name" placeholder="项目名称" />
        </el-form-item>
        <el-form-item label="公司名称" prop="identity_name">
          <el-input v-model="formData.data.identity_name" placeholder="公司名称"/>
        </el-form-item>
        <el-form-item label="项目简介">
          <el-input type="textarea" rows="2" v-model="formData.data.desc" placeholder="项目简介"/>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="formData.data.sort" type="number" placeholder="序号，越大越前"/>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->
  </div>
</template>

<script>
import {apiGetProjects, apiCreateProject, apiPutProject} from '@/api/projectApi'
import {getCompanyId} from '@/plugins/company'

let formData = {
  id: 0,
  company_id: getCompanyId(),
  name: '',
  identity_name: '',
  desc: '',
  sort: 0,
}

export default {
  name: 'project.project',
  created() {
    this.getProjects()
  },
  data() {
    return {
      // 创建表单
      formData: {
        lock: false,
        data: formData,
        rules: {
          name: [{
            required: true,
            message: '请输入项目名称',
            trigger: 'blur',
          }],
          sort: [{
            required: true,
            message: '请输入序号',
            trigger: 'blur',
          }],
        },
        show: false,
      },
      tableData: [],
      searchData: {
        company_id: getCompanyId(),
        name: '',
        page: 1,
        per_page: 50,
      },
      pageData: {
        total: 0,
      }
    }
  },
  methods: {
    getProjects() {
      apiGetProjects({page: 1, per_page: 500, company_id: getCompanyId(),}).then((res) => {
        this.tableData = res.data
        this.pageData = res.meta
      })
    },
    onSubmit() {
      if (this.formData.lock) {
        return;
      }
      this.formLock()
      this.$refs['project'].validate(valid => {
        if (valid) {
          // 更新逻辑
          if (this.formData.data.id > 0) {
            apiPutProject(this.formData.data.id, this.formData.data).then(() => {
              this.$message.success('修改成功')
              this.getProjects()
              this.closeDialog()
              this.formUnlock()
            }).catch(() => {
              this.formUnlock()
            })
          } else {
            apiCreateProject(this.formData.data).then(() => {
              this.$message.success('创建成功')
              this.getProjects()
              this.closeDialog()
              this.formUnlock()
            }).catch(() => {
              this.formUnlock()
            })
          }
        }
      })
    },
    // 点击更新
    clickUpdate(row) {
      this.formData.data = row
      this.showDialog()
    },
    // 点击创建
    clickCreate() {
      this.initDialog()
      this.showDialog()
    },
    formLock() {
      this.formData.lock = true
    },
    formUnlock() {
      this.formData.lock = false
    },
    // 初始化弹出
    initDialog() {
      this.formData.data = formData
      this.closeDialog()
    },
    // 显示弹窗
    showDialog() {
      this.formData.show = true
    },
    // 关闭弹窗
    closeDialog() {
      this.formData.show = false
    }
  }
}
</script>
